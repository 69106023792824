// /**
// =========================================================
// * Mind Escape Dashboard React - v2.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { useState } from "react";

// // react-router-dom components
// import { Link } from "react-router-dom";

// // @mui material components
// import Switch from "@mui/material/Switch";

// // Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiInput from "components/SuiInput";
// import SuiButton from "components/SuiButton";

// // Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";

// // Images
// import curved9 from "assets/images/curved-images/curved-6.jpg";

// import { useAuth } from "../../../auth-context/auth.context";
// import AuthApi from "../../../api/auth";

// import { useNavigate } from "react-router-dom";

// function SignIn() {
//   // const history = useNavigate();
//   // const { setUser } = useAuth();
//   const { user } = useAuth();

//   const [rememberMe, setRememberMe] = useState(true);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState(undefined);
//   const [buttonText, setButtonText] = useState("Sign in");
//   const handleSetRememberMe = () => setRememberMe(!rememberMe);

//   const handleSignIn = async () => {
//     try {
//       const response = await axios.post('https://backend2-eeedeecf102a.herokuapp.com/api/auth/login/', { email, username, password });
//       console.log(response.data);
//       // console.log(response.data.company.name);
//       console.log(response.data.company.id);
//       // console.log(response.data.success);
//       // console.log(response.data.user.username);
//       const companyName = response.data.company.name;
//       const cid = response.data.company.id
//       if (response.data.success && response.data.user.username) {
//         // console.log('Username from server:', response.data.user.username);
//         // Update the context with the username from the response
//         setUsername(dispatch, response.data.user.username);
//         // console.log('Username in context:', response.data.user.username);
//         setCompany(dispatch, companyName);
//         setCid(dispatch, cid);
//       }
//     } catch (err) {
//       // setError("Login failed. Please check your credentials and try again.");
//       console.error(err);
//     }
//     navigate("/dashboard", { username });

//   };

//   // const login = async (event) => {
//   //   if (event) {
//   //     event.preventDefault();
//   //   }
//   //   if (user && user.token) {
//   //     return history.push("/dashboard");
//   //   }
//   //   if (email === "") {
//   //     return setError("You must enter your email.");
//   //   }
//   //   if (password === "") {
//   //     return setError("You must enter your password");
//   //   }
//   //   setButtonText("Signing in");
//   //   try {
//   //     let response = await AuthApi.Login({
//   //       email,
//   //       password,
//   //     });
//   //     if (response.data && response.data.success === false) {
//   //       return setError(response.data.msg);
//   //     }
//   //     return setProfile(response);
//   //   } catch (err) {
//   //     console.log(err);
//   //     setButtonText("Sign in");
//   //     if (err.response) {
//   //       return setError(err.response.data.msg);
//   //     }
//   //     return setError("There has been an error.");
//   //   }
//   // };

//   // const setProfile = async (response) => {
//   //   console.log(response);
//   //   console.log(response.data);
//   //   let user = { ...response.data.user };
//   //   user.token = response.data.token;
//   //   user = JSON.stringify(user);

    
//   //   setUser(user);
//   //   localStorage.setItem("user", user);
//   //   console.log(user);
//   //   return history.push("/dashboard");
//   // };

//   return (
//     <CoverLayout
//       title="MindEscape dashboard"
//       description={`${user && user.token ? "" : "Enter your email and password to sign in"}`}
//       image={curved9}
//     >
//       {user && user.token ? (
//         <div>
//           <h3 style={{ textAlign: "center" }}>You are already signed in.</h3>
//           <SuiBox mt={4} mb={1}>
//             <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={login}>
//               {`Let's go`}
//             </SuiButton>
//           </SuiBox>
//         </div>
//       ) : (
//         <SuiBox component="form" role="form">
//           <SuiBox mb={2}>
//             <SuiBox mb={1} ml={0.5}>
//               <SuiTypography component="label" variant="caption" fontWeight="bold">
//                 Email
//               </SuiTypography>
//             </SuiBox>
//             <SuiInput
//               defaultValue={email}
//               onChange={(event) => {
//                 setEmail(event.target.value);
//                 setError(undefined);
//               }}
//               type="email"
//               placeholder="Email"
//             />
//           </SuiBox>
//           <SuiBox mb={2}>
//             <SuiBox mb={1} ml={0.5}>
//               <SuiTypography component="label" variant="caption" fontWeight="bold">
//                 Password
//               </SuiTypography>
//             </SuiBox>
//             <SuiInput
//               defaultValue={password}
//               onChange={(event) => {
//                 setPassword(event.target.value);
//                 setError(undefined);
//               }}
//               type="password"
//               placeholder="Password"
//             />
//           </SuiBox>
//           <SuiBox display="flex" alignItems="center">
//             <Switch checked={rememberMe} onChange={handleSetRememberMe} />
//             <SuiTypography
//               variant="button"
//               fontWeight="regular"
//               onClick={handleSetRememberMe}
//               customClass="cursor-pointer user-select-none"
//             >
//               &nbsp;&nbsp;Remember me
//             </SuiTypography>
//           </SuiBox>
//           <SuiBox mt={2} mb={2} textAlign="center">
//             <h6
//               style={{
//                 fontSize: ".8em",
//                 color: "red",
//                 textAlign: "center",
//                 fontWeight: 400,
//                 transition: ".2s all",
//               }}
//             >
//               {error}
//             </h6>
//           </SuiBox>

//           <SuiBox mt={4} mb={1}>
//             <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={login}>
//               {buttonText}
//             </SuiButton>
//           </SuiBox>
//           {/* <SuiBox mt={3} textAlign="center">
//             <SuiTypography variant="button" textColor="text" fontWeight="regular">
//               Don&apos;t have an account?{" "}
//               <SuiTypography
//                 component={Link}
//                 to="/authentication/sign-up"
//                 variant="button"
//                 textColor="info"
//                 fontWeight="medium"
//                 textGradient
//               >
//                 Sign up
//               </SuiTypography>
//             </SuiTypography>
//           </SuiBox> */}
//         </SuiBox>
//       )}
//     </CoverLayout>
//   );
// }

// export default SignIn;

/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useNavigate } from "react-router-dom";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import axios from 'axios';
import { useSoftUIController, setUsername, setCompany, setCid } from "../../../context";


function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [username, setUsernameInput] = useState('') // min mperdepseis to setUsernameInput me to setUsername
  const [password, setPassword] = useState('')
  const [, dispatch] = useSoftUIController();
  const [error, setError] = useState(undefined);

    const companyName = "team"; 

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async () => {
    try {
      const response = await axios.post('https://backend2-eeedeecf102a.herokuapp.com/api/auth/login/', { email, username, password });
      // console.log(response.data);
      // console.log(response.data.company.name);
      // console.log(response.data.company.id);
      // console.log(response.data.success);
      // console.log(response.data.user.username);
      const companyName = response.data.company.name;
      const cid = response.data.company.id
      if (response.data.success ) {
        // console.log('Username from server:', response.data.user.username);
        // Update the context with the username from the response
        setUsername(dispatch, response.data.user.username);
        // console.log('Username in context:', response.data.user.username);
        setCompany(dispatch, companyName);
        setCid(dispatch, cid);

        localStorage.setItem("companyName",companyName );

        localStorage.setItem("cid", cid);

        localStorage.setItem("username", response.data.user.username);

        navigate("/dashboard", { username });
      }
    } catch (err) {
      setError("Login failed. Please check your credentials and try again.");
      // console.error(err);
    }
  };
    
  const handleSignInADHD = async () => {
    try {
      const response = await axios.post('https://adhd-63e54587a1ab.herokuapp.com/api/auth/login/', { email, username, password });
      console.log(response.data);
      console.log(response.data.clinic.name);
      // console.log(response.data.company.id);
      console.log(response.data.success);
      console.log(response.data.user.username);
      const companyName = response.data.clinic.name;
      const cid = response.data.clinic.id
      if (response.data.success) {
        // console.log('Username from server:', response.data.user.username);
        // Update the context with the username from the response
        setUsername(dispatch, response.data.user.username);
        // console.log('Username in context:', response.data.user.username);
        setCompany(dispatch, companyName);
        setCid(dispatch, cid);

        localStorage.setItem("companyName",companyName );

        localStorage.setItem("cid", cid);

        localStorage.setItem("username", response.data.user.username);

   

        navigate("/dashboard", { username });

    
      }
    } catch (err) {
      setError("Login failed. Please check your credentials and try again.");
      // console.error(err);
    }
  


      //  if(companyName!=="team")
      // {
       
      // }else{
      //   navigate("/dashboardStudent", { username });

      // }

  };

  return (
    <CoverLayout
      title="Sign In"
      description="Enter your email and password to log in"
      image={curved9}
    >
      <SoftBox component="form" role="form" >
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" onChange={e => { setEmail(e.target.value), setUsernameInput(e.target.value) }} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password" onChange={e => { setPassword(e.target.value) }} />
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox> */}
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" iconOnly={false} onClick={handleSignIn} fullWidth>
            Log in HR
          </SoftButton>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient"  iconOnly={false} onClick={handleSignInADHD} fullWidth>
            Log in ADHD
          </SoftButton>
          <br/>
        
        </SoftBox>
        {error}
        {/* <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
