import React from "react";
import { createRoot } from 'react-dom/client'; // Updated import
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Mind Escape Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";

// import { AuthProvider } from "auth-context/auth.context";

let user = localStorage.getItem("user");
user = JSON.parse(user);

const container = document.getElementById("root");
const root = createRoot(container); // Create root for React 18+

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      {/* <AuthProvider userData={user}> */}
        <App />
      {/* </AuthProvider> */}
    </SoftUIControllerProvider>
  </BrowserRouter>
);
