import React, { useState, useMemo } from "react";
import SoftPagination from "components/SoftPagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Table as MuiTable } from "@mui/material";



function Table1({ columns, rows }) {
  const candidatesPerPage = 5; 
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(rows.length / candidatesPerPage);
  const startIndex = (currentPage - 1) * candidatesPerPage;
  const endIndex = Math.min(startIndex + candidatesPerPage, rows.length);
  const candidatesForPage = rows.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <SoftPagination
        key={i}
        variant="outlined"
        item
        onClick={() => handlePageChange(i)}
        active={i === currentPage}
      >
        {i}
      </SoftPagination>
    );
  }
  const renderColumns = columns.map(({ name, label, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{ boxShadow: 'none' }} 
      >
        {(() => {
          return label.toUpperCase();
        })()}
      </SoftBox>
    );
  });

  const renderRows = candidatesForPage.map((row, key) => {
    const rowKey = `row-${key}`;
    const tableRow = columns.map(({ name, align, width }) => {
      let template;
      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            width={"100%"}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" style={{width: "100%"}} fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            // width={"100%"}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey} style={{width: "100%"}}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
        {/* <div> */}
        <SoftPagination size="small">
                  <SoftPagination
                    item
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <Icon>keyboard_arrow_left</Icon>
                  </SoftPagination>
                  {paginationItems}
                  <SoftPagination
                    item
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <Icon>keyboard_arrow_right</Icon>
                  </SoftPagination>
                </SoftPagination>
        {/* </div>   */}
      </TableContainer>
    ),
    [columns, candidatesForPage, currentPage, paginationItems]
  );
}

Table1.defaultProps = {
  columns: [],
  rows: [{}],
};

Table1.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table1;
