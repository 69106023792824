/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import SoftInput from "components/SoftInput";
import { useState, useRef, useEffect } from "react";

// @mui material components
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Table from "examples/Tables/Table";
import Table1 from "examples/Tables/Table1";
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Icon from "@mui/material/Icon";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { FaRegSave } from "react-icons/fa";
import apiSector from 'api/Sector';
import { useSoftUIController } from "context";


import 'reactjs-popup/dist/index.css';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '30%',
    maxWidth: 'none',
    borderRadius: "10px",
  },
}));

function MiniStatisticsCard({ bgColor, title, count, percentage, direction }) {
  const [keys, setKeys] = useState([]);
  const [name, setName] = useState([]);
  // const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      cid  = localStorage.getItem("cid");
      fetchKeys();
    }
  }, [open]);

  const saveData = async (name1) => {
    try {
      setName(name1);
      // console.warn(name1);
      cid  = localStorage.getItem("cid");

      // Assuming apiSector is an Axios instance or similar
      const response = await apiSector.post(`?company_id=${cid}`, {
        name: name1
      });

      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchKeys = async () => {
    try {
      const response = await apiSector.get('?company_id=' + cid);
      const data = response.data;
      const formattedData = data.map(job => ({
        sectors: job.name,
        numberOfApplicants: job.num_of_applicants,

      }));

      setKeys(formattedData);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);

      }
    }
  }
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient" >
        <SoftBox p={2}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <SoftTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  <h6>{title.text}</h6>
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {count}{" "}
                  <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={4}>
              <React.Fragment>
                <SoftButton style={{ float: 'right' }} variant="gradient" onClick={handleClickOpen}>
                  <FaPlus />
                </SoftButton>
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  borderRadius="10px"
                >
                  <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title">
                    Create new sector
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <Grid data-information="Positions">
                        {keys &&
                          <Table1
                            columns={[
                              { name: "sectors", label: "sectors", align: "center" },
                              { name: "numberOfApplicants", label: "NO. Candidates", align: "center" },


                            ]}
                            rows={keys}
                          />}
                      </Grid>
                      <br></br>
                      Add a new sector:
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SoftInput placeholder="Sector name..." value={inputValue} onChange={handleInputChange} />
                        <SoftButton variant="gradient" onClick={() => saveData(inputValue)} style={{ marginLeft: '8px' }}>
                          <FaRegSave />
                        </SoftButton>
                      </div>
                    </Typography>
                  </DialogContent>
                </BootstrapDialog>
              </React.Fragment>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
