import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Soft UI Dashboard React examples
import './CandidateInfo.css';
import Grid from "@mui/material/Grid";

import { MdOutlineClose } from "react-icons/md"

// Soft UI Dashboard React examples
import 'reactjs-popup/dist/index.css';
// import SoftBox from 'components/SoftBox';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, RadialBarChart, RadialBar, Tooltip, Legend } from 'recharts';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, ErrorBar } from 'recharts';

import Box from '@mui/material/Box';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import { Tab } from "@mui/material";
// import TabPanel from "@mui/lab/TabPanel";
import { useRef } from "react";
import apiProfile from 'api/Profile';
import { FaFileDownload } from "react-icons/fa";
import SoftButton from "components/SoftButton";
// import { useSoftUIController } from "context";
import { useReactToPrint } from "react-to-print";
// import SoftProgress from "components/SoftProgress";
import ProgressBar from "@ramonak/react-progress-bar";


function CandidateInfoHR({ closePopup,id, name, position }) {
  // const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  // console.log(localStorage.getItem("cid"))
  let { company } = localStorage.getItem("companyName");
  let { username } = localStorage.getItem("username");


  const componentPDF = useRef();
  // const componentPDF1 = useRef();
// console.log(id)

  const generatePDF = useReactToPrint({
    content: () => {
      const content = componentPDF.current;
      // const content1 = componentPDF1.current;

      const container = document.createElement('div');

      // Create company name element
      const companyName = document.createElement('div');
      companyName.textContent = `Company Name: ${company}`;
      companyName.style.float = 'left';
      companyName.style.fontWeight = 'bold';
      companyName.style.marginRight = '10px';
      companyName.style.fontSize = '15px'
      // Create employee name element
      // const employeeName = document.createElement('div');
      // employeeName.textContent = `Employee Name:  ${username}`;
      // employeeName.style.float = 'right';
      // employeeName.style.fontWeight = 'bold';
      // employeeName.style.marginLeft = '10px';
      // employeeName.style.fontSize = '15px'

      const can = document.createElement('div');
      can.textContent = `Candidate: ${name}`;
      can.style.float = 'right';
      can.style.fontWeight = 'bold';
      can.style.marginRight = '10px';
      can.style.fontSize = '15px'
      // Append the date text to the container
      const dateText = document.createElement('div');
      dateText.textContent = `Data Saved on: ${new Date().toLocaleString()}`;
      dateText.style.fontSize = '15px';
      dateText.style.float = 'right';
      dateText.style.marginLeft = '20px';

      // Create a clear fix element
      const clearFix = document.createElement('div');
      clearFix.style.clear = 'both';


      container.appendChild(companyName);
      // container.appendChild(employeeName);

      container.appendChild(can);

      container.appendChild(clearFix);


      // Append the content to the container
      container.appendChild(content.cloneNode(true));
      container.appendChild(dateText);

      // container.appendChild(content1.cloneNode(true));

      return container;
    },
    documentTitle: 'Informations',
    pageStyle: `
      @page {
        size: A2 landscape; 
        margin: 100%; 
      }
      @media print {
        body {
          margin: 0;
        }
      }
    `,
  });

  const [valueTab, setValueTab] = React.useState("1");
  const [profile, setProfiles] = useState([]);


  let popupRef = useRef();

  useEffect(() => {
    cid  = localStorage.getItem("cid");
    company = localStorage.getItem("companyName");
    username = localStorage.getItem("username");
    fetchPr();
  }, [])

  // const cid1 = 6;
  const fetchPr = async () => {
    try {
      const response = await apiProfile.get('?user_id=' + id);
      setProfiles(response.data);

    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }



  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };


  function checkClickOutside(e) {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      closePopup();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside);
    return () => {
      document.removeEventListener('mousedown', checkClickOutside);
    };
  }, [closePopup])


  if (!profile || !profile.personality) {
    return <></>;
  }



  const facesets = profile.facesets;

  const behaviors = profile.behaviour;

  const radarData = {
    "2": [
      { name: 'Imagination', x: facesets.imagination },
      { name: 'Artistic Interests', x: facesets.artistic_interests },
      { name: 'Depth of Emotions', x: facesets.depth_of_emotions },
      { name: 'Willingness to Experiment', x: facesets.willingness_to_experiment },
      { name: 'Intellectual Curiosity', x: facesets.intellectual_curiosity },
      { name: 'Tolerance for Diversity', x: facesets.tolerance_for_diversity }
    ],
    "3": [
      { name: 'Sense of Competence', x: facesets.sense_of_competence },
      { name: 'Orderliness', x: facesets.orderliness },
      { name: 'Sense of Responsibility', x: facesets.sense_of_responsibility },
      { name: 'Achievement Striving', x: facesets.achievement_striving },
      { name: 'Self-Discipline', x: facesets.self_discipline },
      { name: 'Deliberateness', x: facesets.deliberateness }
    ],
    "4": [
      { name: 'Warmth', x: facesets.warmth },
      { name: 'Gregariousness', x: facesets.gregariousness },
      { name: 'Assertiveness', x: facesets.assertiveness },
      { name: 'Activity Level', x: facesets.activity_level },
      { name: 'Excitement-Seeking', x: facesets.excitement_seeking },
      { name: 'Positive Emotions', x: facesets.positive_emotions }
    ],
    "5": [
      { name: 'Trust in others', x: facesets.trust_in_others },
      { name: 'Sincerity', x: facesets.sincerity },
      { name: 'Altruism', x: facesets.altruism },
      { name: 'Compliance', x: facesets.compliance },
      { name: 'Modesty', x: facesets.modesty },
      { name: 'Sympathy', x: facesets.sympathy }
    ],
    "6": [
      { name: 'Anxiety', x: facesets.anxiety },
      { name: 'Angry Hostility', x: facesets.angry_hostility },
      { name: 'Moodiness/Contentment', x: facesets.moodiness_contentment },
      { name: 'Self-Consciousness', x: facesets.self_consciousness },
      { name: 'Self-Indulgence', x: facesets.self_indulgence },
      { name: 'Sensitivity to Stress', x: facesets.sensitivity_to_stress }
    ]
  };

  const dataRDC1 = [

    {
      "name1": "low imagination",
      "name2": "high imagination",
      "uv": facesets.imagination-50,
      "fill": "#317e89"
    },
    {
      "name1": "low artistic interests",
      "name2": "high artistic interests",
      "uv": facesets.artistic_interests-50,
      "fill": "#3592a0"
    },
    {
      "name1": "low depth of emotions",
      "name2": "high depth of emotions",
      "uv": facesets.depth_of_emotions-50,
      "fill": "#3ba7ba"
    },
    {
      "name1": "low willingness to experiment",
      "name2": "high willingness to experiment",
      "uv": facesets.willingness_to_experiment-50,
      "fill": "#40b7cd"
    },
    {
      "name1": "low intellectual curiosity",
      "name2": "high intellectual curiosity",
      "uv": facesets.intellectual_curiosity-50,
      "fill": "#48c1d4"
    },
    {
      "name1": "low tolerance for diversity",
      "name2": "high tolerance for diversity",
      "uv": facesets.tolerance_for_diversity-50,
      "fill": "#5eccdc"
    },
  ]

  const dataRDC2 = [

    {
      "name1": "low deliberateness",
      "name2": "high deliberateness",
      "uv": facesets.deliberateness-50,
      "fill": "#922374"
    },
    {
      "name1": "low sense of competence",
      "name2": "high  sense of competence",
      "uv": facesets.sense_of_competence-50,
      "fill": "#8f156a"
    },
    {
      "name1": "low orderliness",
      "name2": "high orderliness",
      "uv": facesets.orderliness-50,
      "fill": "#a0467f"
    },
    {
      "name1": "low sense of responsibility",
      "name2": "high sense of responsibility",
      "uv": facesets.sense_of_responsibility-50,
      "fill": "#ba7ba1"
    },
    {
      "name1": "low achievement striving",
      "name2": "high achievement striving",
      "uv":  facesets.achievement_striving-50,
      "fill": "#d6afc6"
    },
    {
      "name1": "low self-discipline",
      "name2": "high self-discipline",
      "uv": facesets.self_discipline-50,
      "fill": "#eedfe7"
    },

  ]

  const dataRDC3 = [

    {
      "name1": "low warmth",
      "name2": "high warmth",
      "uv": facesets.warmth-50,
      "fill": "#00bf34"
    },
    {
      "name1": "low gregariousness",
      "name2": "high gregariousness",
      "uv": facesets.gregariousness-50,
      "fill": "#00ca58"
    },
    {
      "name1": "low assertiveness",
      "name2": "high assertiveness",
      "uv":  facesets.assertiveness-50,
      "fill": "#00d56f"
    },
    {
      "name1": "low activity level",
      "name2": "high activity level",
      "uv":  facesets.activity_level-50,
      "fill": "#32de8b"
    },
    {
      "name1": "low excitement-seeking",
      "name2": "high excitement-seeking",
      "uv": facesets.excitement_seeking-50,
      "fill": "#83e7ae"
    },
    {
      "name1": "low positive emotions",
      "name2": "high positive emotions",
      "uv": facesets.positive_emotions-50,
      "fill": "#b7f1ce"
    },
  ]

  const dataRDC4 = [
    {
      "name1": "low trust in others",
      "name2": "high trust in others",
      "uv":  facesets.trust_in_others-50,
      "fill": "#d85c1f"
    },
    {
      "name1": "low sincerity",
      "name2": "high sincerity",
      "uv": facesets.sincerity-50,
      "fill": "#e97c2f"
    },
    {
      "name1": "low altruism",
      "name2": "high altruism",
      "uv": facesets.altruism-50,
      "fill": "#f1a23a"
    },
    {
      "name1": "low compliance",
      "name2": "high compliance",
      "uv": facesets.compliance-50,
      "fill": "#f5b941"
    },
    {
      "name1": "low modesty",
      "name2": "high modesty",
      "uv": facesets.modesty-50,
      "fill": "#f9d048"
    },
    {
      "name1": "low sympathy",
      "name2": "high sympathy",
      "uv": facesets.sympathy-50,
      "fill": "#fae04a"
    },
  ]

  const dataRDC5 = [

    {
      "name1": "low anxiety",
      "name2": "high anxiety",
      "uv": facesets.anxiety-50,
      "fill": "#b22126"
    },
    {
      "name1": "low moodiness/contentment",
      "name2": "high moodiness/contentment",
      "uv": facesets.moodiness_contentment-50,
      "fill": "#ce3238"
    },
    {
      "name1": "low self-consciousness",
      "name2": "high self-consciousness",
      "uv": facesets.self_consciousness-50,
      "fill": "#e13b3e"
    },
    {
      "name1": "low self-indulgence",
      "name2": "high self-indulgence",
      "uv": facesets.self_indulgence-50,
      "fill": "#f04440"
    },
    {
      "name1": "low sensitivity to stress",
      "name2": "high sensitivity to stress",
      "uv": facesets.sensitivity_to_stress-50,
      "fill": "#ea5458"
    },
    {
      "name1": "low angry hostility",
      "name2": "high angry hostility",
      "uv": facesets.angry_hostility-50,
      "fill": "#e07479"
    },
  ]

    
  const dataOB = [

    {
      "name1": "low exploration",
      "name2": "high exploration",
      "uv": behaviors.exploration*2,
      "fill": "#317e89"
    },

    
    {
      "name1": "non trained",
      "name2": "trained",
      "uv": 50,
      "fill": "#317e89"
    },

  ]

  const dataCB = [

    {
      "name1": "low impatience",
      "name2": "high impatience",
      "uv": behaviors.impatience * 250,
      "fill": "#922374"
    },
    {
      "name1": "low rightpref",
      "name2": "high rightpref",
      "uv": behaviors.rightpref * 10,
      "fill":  "#8f156a"
    },
    {
      "name1": "low panic",
      "name2": "high panic",
      "uv": behaviors.panic*0.5,
      "fill":  "#a0467f"
    },

  ]



  
  const dataEB = [

    {
      "name1": "low leadership",
      "name2": "high leadership",
      "uv": behaviors.leadership*0.5,
      "fill": "#00bf34"
    },
    {
      "name1": "low impatience",
      "name2": "high impatience",
      "uv": behaviors.impatience*300,
      "fill": "#00ca58"
    },
    {
      "name1": "low walkspeed",
      "name2": "high walkspeed",
      "uv":  behaviors.walkspeed_m,
      "fill": "#00d56f"
    },  
  ]
  
  const dataAB = [
    {
      "name1": "low impatience",
      "name2": "high impatience",
      "uv":  behaviors.impatience*150,
      "fill": "#d85c1f"
    },
    {
      "name1": "low rightpref",
      "name2": "high rightpref",
      "uv": behaviors.rightpref*4,
      "fill": "#e97c2f"
    },   
  ]
  const dataNB = [
    {
      "name1": "low leadership",
      "name2": "high leadership",
      "uv":  behaviors.leadership*0.5,
      "fill": "#b22126"
    },
    {
      "name1": "low panic",
      "name2": "high panic",
      "uv": behaviors.panic*0.5,
      "fill": "#ce3238"
    },   
  ]



  const { openness, conscientiousness, extroversion, agreeableness, neuroticism } = profile.personality;

  const opennessTemp = ((openness / 8.2) * 100).toFixed(2);
  let opennessFinal = 0;
  if (opennessTemp < 0){
    opennessFinal = 100 + parseFloat(opennessTemp)
  }else{
    opennessFinal = 50 + parseFloat(opennessTemp);
  }

  const conscientiousnessTemp = ((conscientiousness / 1.447) * 100).toFixed(2);
  let conscientiousnessFinal = 0;
  if (conscientiousnessTemp < 0){
    conscientiousnessFinal = 100 + parseFloat(conscientiousnessTemp)
  }else{
    conscientiousnessFinal = 50 + parseFloat(conscientiousnessTemp);
  }

  
  const extroversionTemp = ((extroversion / 10.22) * 100).toFixed(2);
  let extroversionFinal = 0;
  if (extroversionTemp < 0){
    extroversionFinal = 100 + parseFloat(extroversionTemp)
  }else{
    extroversionFinal = 50 + parseFloat(extroversionTemp);
  }

  const agreeablenessTemp = ((agreeableness / 13.29) * 100).toFixed(2);
  let agreeablenessFinal = 0;
  if (agreeablenessTemp < 0){
    agreeablenessFinal = 100 + parseFloat(agreeablenessTemp)
  }else{
    agreeablenessFinal = 50 + parseFloat(agreeablenessTemp);
  }

  
  const neuroticismTemp = ((neuroticism / 6.06) * 100).toFixed(2);
  let neuroticismFinal = 0;
  if (neuroticismTemp < 0){
    neuroticismFinal = 100 + parseFloat(neuroticismTemp)
  }else{
    neuroticismFinal = 45 + parseFloat(neuroticismTemp);
  }







  const data = [
    { name: 'Openness', x: opennessFinal},
    { name: 'Conscientiousness', x: conscientiousnessFinal },
    { name: 'Extraversion', x: extroversionFinal },
    { name: 'Agreeableness', x: agreeablenessFinal},
    { name: 'Neuroticism', x: neuroticismFinal }
  ]


  return (
    <div className="big-popup" ref={popupRef}>
      <div className="big-popup-content">
        <MdOutlineClose className="close-button" onClick={closePopup} />
        <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500' }}> {name} <br /> {position}  </p>
        <SoftButton variant="gradient" style={{ float: 'right' }} iconOnly={false} onClick={generatePDF}>
          Print <FaFileDownload />
        </SoftButton>
        <br></br>
        <Box ref={componentPDF}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <RadarChart
              height={550}
              width={550}
              outerRadius="50%"
              data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis
                angle={0}
                domain={[-50, 50]} />
              <Radar dataKey="x" stroke="black" fillOpacity={0} />
            </RadarChart>
          </Box>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Openness</p>
             <ProgressBar completed={opennessFinal} bgColor='#3B8EA5' /> 
          
            {/* <SoftProgress value='50' color='#3B8EA5' label /> */}
            {opennessFinal > 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
             This candidate demonstrates a strong willingness to embrace new challenges, think creatively, and contribute fresh ideas that can foster positive change within the organization. Their high openness score indicates they are adaptable and thrive in dynamic environments where innovation and imaginative problem-solving are encouraged. They are likely to excel in roles that require continuous learning, flexibility, and creative input.

However, it's important to note that candidates with high openness may struggle with repetitive or routine tasks that lack variety and creativity. During the interview, consider exploring how they handle monotonous work and whether they’ve developed strategies to stay engaged in such situations. Additionally, assess their ability to balance creative thinking with the practical demands of the role.
 </p>
}
{opennessFinal < 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
              This candidate prefers structured, systematic, and logical approaches to their work, valuing consistency and clear processes. Their preference for order and predictability means they are less likely to seek out change and may favor maintaining the status quo in their work style. They excel in environments where procedures are well-defined, and expectations are clear.

However, candidates with this mindset may be slower to adapt to rapidly changing situations or resist unconventional approaches. During the interview, consider discussing how they respond to unexpected challenges or shifts in priorities. It may also be helpful to explore their willingness to step outside their comfort zone when innovation or flexibility is required in the role.
</p>
}
            
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataOB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginRight: '10px' }}>Conscientiousness</p>
           <ProgressBar completed={ conscientiousnessFinal} bgColor='#BA7BA1' />            

            {/* <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Conscientiousness concerns the way in which we control, regulate and direct our impulses. Impulses are not inherently bad; occasionally time constraints require a snap decision and acting on our first impulse can be an effective response. Also, in times of play rather than work, acting spontaneously and impulsively can be fun. Impulsive individuals can be seen by others as colourful, fun-to-be-with and zany.</p> */}
           
            {conscientiousnessFinal > 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
           Candidates with high conscientiousness prefer structured, organized approaches to their work, ensuring tasks are completed with precision and attention to detail. They are highly ambitious, using their determination, discipline, and thorough preparation to achieve both personal and professional goals. These individuals are diligent, reliable, and excel at planning and strategizing to optimize their performance and productivity.

However, their strong focus on structure and order may make them less comfortable in highly flexible or unstructured environments. During the interview, consider exploring how they handle unexpected changes or situations that require quick, adaptive thinking. It may also be valuable to assess their ability to balance perfectionism with efficiency, especially when working under tight deadlines.
 </p>
}
{conscientiousnessFinal < 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
            Candidates with lower conscientiousness scores tend to be more flexible and spontaneous in their approach to work. They are comfortable operating without strict structures and may prefer a more adaptable, free-flowing work style. While this flexibility can foster creativity and the ability to adjust quickly to changing circumstances, it may also present challenges when it comes to meeting strict deadlines, staying organized, or managing time efficiently.

During the interview, consider discussing how they prioritize tasks and handle time-sensitive projects. It may also be helpful to explore any strategies they use to stay on track in more structured environments or when faced with multiple competing deadlines.
</p>
}
           
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataCB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-10, 10]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Extraversion</p>
            <ProgressBar completed={extroversionFinal} bgColor='#32DE8A' />
            {/* <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Extraversion is marked by pronounced engagement with the external world. Extraverts enjoy being with people, are full of energy and often experience positive emotions. They tend to be enthusiastic and action-oriented individuals who are likely to say &quot;Yes!&quot; or &quot;Let&apos; s go!&quot; to opportunities for excitement. In groups they like to talk, assert themselves and draw attention to themselves.</p> */}
            
            {extroversionFinal > 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
        Extroverted candidates thrive on social interaction and are often energized by engaging with colleagues, making them highly productive in team settings and collaborative discussions. They bring enthusiasm and strong communication skills to group projects, contributing to a dynamic and interactive work environment.

However, these candidates may find it challenging to stay motivated when working on solo projects or in remote, isolated settings where opportunities for social engagement are limited. During the interview, consider exploring how they manage independent tasks and maintain focus without frequent interaction. It may also be useful to assess their adaptability to remote or hybrid work environments if the role requires it.
 </p>
}
{extroversionFinal < 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
            These candidates exhibit high levels of introversion and tend to excel when working independently, producing their best results in quiet, focused environments. They are self-motivated, thoughtful, and often prefer tasks that allow for deep concentration and individual problem-solving.

However, introverted employees may find it challenging to engage in frequent collaboration or participate in group discussions. They might be less inclined to openly share ideas or seek out team interactions. During the interview, consider exploring their comfort level with collaborative projects and how they approach communication within a team setting. It may also be helpful to discuss strategies they use to balance independent work with necessary team interactions.
</p>
}
            
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataEB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-10, 10]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginRight: '10px' }}>Agreeableness</p>
            <ProgressBar completed={agreeablenessFinal} bgColor='#F5B841' />
            {/* <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Agreeableness reflects individual differences in concern about co-operation and social harmony. Agreeable individuals value getting along with others. They are therefore considerate, friendly, generous, helpful and willing to compromise their interests with others&apos;. Agreeable people also have an optimistic view of human nature. They believe people are basically honest, decent and trustworthy.</p> */}
            
            {agreeablenessFinal > 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
        Candidates with a high agreeableness score are cooperative, supportive, and eager to assist others with challenging tasks. They excel in collaborative environments, fostering positive relationships with team members and contributing to a harmonious workplace. These individuals are empathetic, open to feedback, and focused on resolving conflicts and finding solutions that benefit the team.

However, their strong desire to maintain harmony may lead them to avoid confrontation or hesitate to voice dissenting opinions. During the interview, consider discussing how they handle situations where they disagree with colleagues or need to provide constructive feedback. It may also be helpful to explore how they balance their cooperative nature with assertiveness when necessary.
 </p>
}
{agreeablenessFinal < 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
          Candidates with low agreeableness scores tend to be more assertive, direct, and confident in expressing their ideas and opinions. They are often headstrong and unafraid to challenge the status quo, which can be valuable in roles that require critical thinking, negotiation, or decisive leadership.

However, this assertiveness may make it challenging for them to be receptive to others' viewpoints or to show empathy in team settings. They might struggle with collaboration if they prioritize their own ideas over group consensus. During the interview, consider exploring how they handle feedback, approach teamwork, and navigate situations that require compromise or active listening. It may also be helpful to assess their ability to balance assertiveness with empathy in collaborative environments.
</p>
}
            
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataAB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-10, 10]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Neuroticism</p>
            <ProgressBar completed={neuroticismFinal} bgColor='#B22125' />
            {/* <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>People high in Natural Reactions are emotionally reactive. They respond emotionally to events that do not affect a lot of people and their reactions tend to be more intense. They are more likely to interpret ordinary situations as threatening and minor frustrations as hopelessly difficult. Their negative emotional reactions tend to persist for unusually long periods of time.  These problems can diminish a person&apos;s ability to think clearly, make decisions and cope effectively with stress.</p> */}
            
            {neuroticismFinal > 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
        Candidates with high emotional sensitivity may find it challenging to perform in high-pressure situations, as they can struggle with managing their emotions and may become overly concerned when mistakes occur. They tend to be self-critical and may experience heightened stress or anxiety in fast-paced, high-stakes environments.

These individuals often prefer calmer, more structured work settings where they can focus without the added pressure, helping them maintain emotional stability and avoid mood swings. During the interview, consider discussing how they handle stressful situations, feedback, and setbacks. It may also be helpful to explore any coping strategies they use to manage stress and maintain productivity under pressure.
 </p>
}
{neuroticismFinal < 50 &&
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>
          Applicants with high emotional stability are able to stay calm and focused, even in high-pressure situations. They tend to approach their work in a stable, predictable manner, handling challenges with a level-headed mindset. These candidates often maintain a positive outlook, viewing challenging projects as opportunities rather than stressors.

Their ability to manage stress effectively allows them to maintain their wellbeing, ensuring they remain productive and composed during demanding times. During the interview, consider discussing how they cope with high-pressure scenarios and what strategies they use to maintain their mental and emotional resilience. It may also be useful to explore their ability to stay focused and positive when facing setbacks or obstacles.
</p>
}
                      
            
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataNB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-10, 10]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <br />
          <div>
          </div>
        </Box>
      </div>
    </div >


  );
}

// Define propTypes for the component
CandidateInfoHR.propTypes = {
  name: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};


export default CandidateInfoHR;
