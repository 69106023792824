import React from 'react';
import PropTypes from "prop-types";
import { FaPlus, FaRegSave } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import SoftInput from "components/SoftInput";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Table1 from "examples/Tables/Table1";
import apiPosition from 'api/Positions';



// Styled components
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '90%',
    borderRadius:"10px",
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Position({ bgColor, title, count, percentage, direction }) {
  const [open, setOpen] = React.useState(false);

  const [keys, setKeys] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [name, setName] = useState([]);
  // const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  // const [isChecked, setIsChecked] = useState(false);

  // Handle change event
  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const [selectedProfile, setSelectedProfile] = useState('');
  const handleProfileChange = (event) => {
    setSelectedProfile(event.target.value);
    // console.log(`Selected profile: ${event.target.value}`);
  };
  
  const [selectedSector, setSelectedSector] = useState('');
  const handleSectorChange = (event) => {

    console.log(event.target.value);
    setSelectedSector(event.target.value);
    // console.log(`Selected sector: ${event.target.value}`);
  };
  
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event) => {
    // setInputValue(event.target.value);
  };

  const saveData = async (name1,sector,profile) => {
    try {
      setName(name1);
      cid  = localStorage.getItem("cid");
      // console.log(cid)

      // console.warn(name1);
      // Assuming apiPosition is an Axios instance or similar
      const response = await apiPosition.post(`?company_id=${cid}&sector_name=${sector}`, {
        name: name1,
        standard_profile:profile,
        // open:checked,
      });

      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      cid  = localStorage.getItem("cid");
      fetchKeys();
    }
  }, [open]);

  const fetchKeys = async () => {
    try {
      const response = await apiPosition.get('?company_id=' + cid);
      const data = response.data;
      const formattedData = data.positions.map((job) => ({
        activate: (<Checkbox color="secondary" disabled={job.open} defaultChecked={job.open} style={{ border: '2px  solid #5e6063', padding: '2px' }}  {...label} /> ),
        position: job.name,
      }));
      const profiles = [...new Set(data.positions.map(job => job.standard_profile))];
      profiles.unshift(" ");
      setProfiles(profiles);
      setKeys(formattedData);
      
      const sectors = data.sectors;
      sectors.unshift(" ");
      setSectors(sectors);

    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);

      }
    }
  }
 
  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" && (
              <Grid item>
                <SoftBox
                  variant="gradient"
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="xl"
                >
                  <SoftButton><FaPlus /></SoftButton>
                </SoftBox>
              </Grid>
            )}
            <Grid item xs={8}>
              <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <SoftTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  <h6>{title.text}</h6>
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {count}{" "}
                  <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </Grid>
            {direction === "right" && (
              <Grid item xs={4}>
                <React.Fragment>
                  <SoftButton style={{ float: 'right' }} variant="gradient" onClick={handleClickOpen}>
                    <FaPlus />
                  </SoftButton>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title">
                      Open Position
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                      <Typography gutterBottom>
                        <Grid data-information="Positions">
                        {keys && 
                          <Table1
                            columns={[
                              { name: "activate",label: "activate", align: "center" },
                              { name: "position",label: "positions", align: "center" },
                            ]}
                            rows={keys}
                          />}
                        </Grid>
                        <br></br>
                        <div style={{
                          border: '1px solid #ccc',
                          padding: '16px',
                          width: '100%',
                          borderRadius: '15px',
                          boxShadow: '0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.25)',
                          backgroundColor: '#FFFF',
                          fontFamily: 'Arial, sans-serif',
                          fontSize: '14px',

                        }}>
                           <h5>Add a new position:</h5>
                            <SoftInput placeholder="Position name..." value={inputValue} onChange={handleInputChange}/>
                            <label style={{ display: 'block', marginBottom: '8px' }}>
                            {/* <FormControlLabel style={{marginLeft:'0px'}} control={<Checkbox value={isChecked} onChange={e=> handleCheckboxChange(e)} style={{ border: '2px  solid #5e6063', padding: '2px'}} />}  label="Activate open position" /> */}
                            </label>
                          <label style={{ display: 'block', marginBottom: '8px' }}>
                            Pick the sector:
                            <select 
                              name="selectedSector" 
                              style={{ marginLeft: '8px', padding: '4px', borderRadius: '5px' }}
                              value={selectedSector}
                              onChange={handleSectorChange}
                            > 
                            {sectors.map((sector, index) => (
                              <option key={index} value={sector}>
                                {sector}
                              </option>
                            ))}
                            </select>
                          </label>
                          {/* <br /> */}
                          <label style={{ display: 'block', marginBottom: '8px' }}>
                            Personality Template:
                            <select 
                              name="selectedPersonality" 
                              style={{ marginLeft: '8px', padding: '4px', borderRadius: '5px' }}
                              value={selectedProfile}
                              onChange={handleProfileChange}
                            >
                            {profiles.map((profile, index) => (
                              <option key={index} value={profile}>
                                {profile}
                              </option>
                            ))}
                           </select>
                          </label>
                          <SoftButton variant="gradient" onClick={() => saveData(inputValue,selectedSector,selectedProfile)} iconOnly={false} style={{ marginLeft: '8px' }}>
                            Save <FaRegSave style={{ marginLeft: '4px' }} />
                          </SoftButton>
                        </div>
                      </Typography>
                    </DialogContent>
                  </BootstrapDialog>
                </React.Fragment>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

Position.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

Position.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  direction: PropTypes.oneOf(["right", "left"]),
};

export default Position;
