import PropTypes from 'prop-types';
import SoftButton from "components/SoftButton";
import { FaFileDownload } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from "examples/Tables/Table";
import SoftProgress from "components/SoftProgress";
import { FaUsers } from "react-icons/fa";
import apiOpenPositions from 'api/OpenPositions';
import { useState, useEffect } from 'react';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CandidateInfoDialog = ({ positionToShow, componentPDF, generatePDF }) => {
  const [open1, setOpen1] = React.useState(false);
  const [rows, setRows] = useState([]);

  let { cid } = localStorage.getItem("cid");

  useEffect(() => {
    if (open) {
      cid = localStorage.getItem("cid");
      fetchRows();
    }
  }, [open]);

  const handleClickOpenCandidate = () => {
    setOpen1(true);
  };

  const handleCloseCandidate = () => {
    setOpen1(false);
  };

  const fetchRows = async () => {
    try {
      const response = await apiOpenPositions.get('?company_id=' + cid);
      const data = response.data;

      // Find the job position based on positionToShow
      const job = data.find(job => job.position === positionToShow);

      // Extract employees for the selected job position
      const formattedData = job ? job.candidates.map(cand => ({
        name: cand.user,
        positionfit: <SoftProgress value2={positionToShow} value={cand.position_fit * 100} variant="gradient" label />,
      })) : [];

      setRows(formattedData);
      // console.log('data', formattedData);
      // console.log('formatted data', formattedData);
      // console.log('Data1', response.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);

      }
    }
  }

  return (
    <React.Fragment>
      <SoftButton variant="gradient" onClick={handleClickOpenCandidate}>
        <FaUsers />
      </SoftButton>
      <BootstrapDialog
        onClose={handleCloseCandidate}
        aria-labelledby="customized-dialog-title1"
        open={open1}
      >
        <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title1">
          Information about candidates
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseCandidate}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Grid ref={componentPDF} data-information="Position fit for candidates">
              <Table
                columns={[
                  { name: "name",label:"name",  align: "left" },
                  { name: "positionfit",label:"Position Fit", align: "left" },

                ]}
                rows={rows}
              />
            </Grid>
            <br></br>
            <SoftButton variant="gradient" style={{ float: 'right' }} iconOnly={false} onClick={generatePDF}>
              Print <FaFileDownload />
            </SoftButton>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

CandidateInfoDialog.propTypes = {
  open1: PropTypes.bool.isRequired,
  handleCloseCandidate: PropTypes.func.isRequired,
  componentPDF: PropTypes.object.isRequired,
  generatePDF: PropTypes.func.isRequired,
  positionToShow: PropTypes.func.isRequired,
};

export default CandidateInfoDialog;