/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useRef, useEffect } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import { IoMail } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniStatisticsCard2 from "examples/Cards/StatisticsCards/MiniStatisticsCard2";
import MiniStatisticsCard3 from "examples/Cards/StatisticsCards/MiniStatisticsCard3";
import Position from "examples/Cards/StatisticsCards/Position";
import Sectors from "examples/Cards/StatisticsCards/Sectors";
import axios from 'axios'

// Soft UI Dashboard React base styles
import TabPanel from "@mui/lab/TabPanel"

// Soft UI Dashboard React examples
import Table1 from "examples/Tables/Table1";
import { FaFileDownload, FaRegCalendarPlus, FaInfo } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Tab } from "@mui/material";
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";

import 'reactjs-popup/dist/index.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField'
import CandidateInfo from "./CandidateInfo";
import EmployeeInfoDialog from "./EmployeeInfoDialog";
import CandidateInfoDialog from "./CandidateInfoDialog";
import { useSoftUIController } from "context";

import apiBaseStats from 'api/BaseStats';
import apiSerialKeys from 'api/SerialKeys';
import apiEmployeeDetails from 'api/EmployeeDetails';
import apiCandidateDetails from 'api/CandidateDetails';
import apiOverviewFit from 'api/OverviewFitADHD';
import apiOpenPositions from 'api/OpenPositions';
import apiGameMetrics from 'api/GameMetrics';


function ADHD() {

  const [gameStats, setgameStats] = useState([]);
  const location = useLocation();
  const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  let { company } = localStorage.getItem("companyName");
  let { username } = localStorage.getItem("username");


  const [baseStats, setbaseStats] = useState([]);
  const [keys, setSerialKeys] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [overviewFit, setoverviewFit] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    cid = localStorage.getItem("cid");
    company = localStorage.getItem("companyName");
    username = localStorage.getItem("username");
    // fetchGame();
    // fetchBaseStats();
    // fetchSerialKeys();
    fetchOverviewFit();
    // fetchRows();

  }, [])

  const fetchRows = async () => {
    try {
      const response = await apiOpenPositions.get('?company_id=' + cid);
      const data = response.data;

      const formattedData = data.map(job => ({
        positions: job.position,
        numberOfApplicants: job.applicants,
        employees: (
          <EmployeeInfoDialog
            positionToShow={job.position}
            componentPDF={componentPDF}
            generatePDF={generatePDF}
          />
        ),
        candidates:
          (
            <CandidateInfoDialog
              positionToShow={job.position}
              componentPDF={componentPDF}
              generatePDF={generatePDF}
            />
          )
      }));
      setRows(formattedData);
      // console.log('formatted data', formattedData);
      // console.log('Data1', response.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);

      }
    }

  }

  const fetchGame = async () => {
    try {
      const response = await apiGameMetrics.get('?company_id=' + cid);
      setgameStats(response.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const fetchBaseStats = async () => {
    try {
      const response = await apiBaseStats.get('?company_id=' + cid);
      setbaseStats(response.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const fetchSerialKeys = async () => {
    try {
      const response = await apiSerialKeys.get('?company_id=' + cid);
      const candidateKeys = response.data.unused_candidate_keys || [];
      const employeeKeys = response.data.unused_employee_keys || [];

      const keys = candidateKeys.map((candidateKey, index) => {
        const employeeKey = employeeKeys[index] || {};
        return {
          unused_key_candidate: (
            <div
              key={`candidate-${index}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {candidateKey.key ? (
                <>

                  <FormControlLabel
                    control={<Checkbox color="secondary" disabled={candidateKey.given} defaultChecked={candidateKey.given} 
                    // onClick={() => handleChecked(candidateKey.key)} 
                    style={{ border: '2px solid #5e6063', padding: '2px' }} />}
                    label={candidateKey.key}
                  />
                  {/* <SoftButton onClick={handleOpenMail} variant="gradient">
                    <IoMail />
                  </SoftButton> */}

                  <CopyToClipboard text={candidateKey.key} >
                    <SoftButton variant="gradient" disabled={candidateKey.given}>   <FaCopy /> </SoftButton>
                  </CopyToClipboard>
                </>
              ) : (
                <span>No Candidate Key</span>
              )}
            </div>
          ),
          unused_key_employee: (
            <div
              key={`candidate-${index}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {employeeKey.key ? (
                <>
                  <FormControlLabel
                    control={<Checkbox color="primary" disabled={employeeKey.given} 
                    defaultChecked={employeeKey.given} 
                    // onClick={() => handleChecked(employeeKey.key)} 
                    style={{ border: '2px solid #5e6063', padding: '2px' }} />}
                    label={employeeKey.key}
                  />
                  {/* <SoftButton onClick={handleOpenMail} variant="gradient">
                    <IoMail />
                  </SoftButton> */}

                  <CopyToClipboard text={employeeKey.key} >
                    <SoftButton variant="gradient" disabled={employeeKey.given}>   <FaCopy /> </SoftButton>
                  </CopyToClipboard>
                </>
              ) : (
                <span></span>
              )}
            </div>
          ),
        };
      });
      setSerialKeys(keys);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };

  const fetchOverviewFit = async () => {
    try {
      const response = await apiOverviewFit.get('?clinic_id=' + cid);
      const data = response.data.patients;

      
      const overviewdata = data.filter(overview => overview.is_control_sample === false).map((overview, index) => ({

        // info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(overview.name, overview.desired_fit[0])}><FaInfo /></SoftButton>,
        username: overview.username,   
        has_adhd: 
        <div
                key={`patient-${index}`}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
        <FormControlLabel control={<Checkbox color="primary"
        defaultChecked={overview.has_adhd} 
        onClick={() => handleCheckedADHD(overview, overview.has_adhd)}
         style={{ border: '2px solid #5e6063', padding: '2px' }} />}
                    label={overview.has_adhd}
                  />
                  </div>
          ,
          has_hyperactivity:   <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary" 
  defaultChecked={overview.has_hyperactivity} 
  onClick={() => handleCheckedHyper(overview, overview.has_hyperactivity)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_hyperactivity}
            />
            </div>,  

          has_add:  <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary"
  defaultChecked={overview.has_add} 
  onClick={() => handleCheckedADD(overview, overview.has_add)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_add}
            />
            </div>
            ,              
          has_autism: 
           <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary"  
  defaultChecked={overview.has_autism} 
  onClick={() => handleCheckedAutism(overview, overview.has_autism)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_autism}
            />
            </div>,  

          notes: <TextField id="outlined-basic" 
          label="Notes" defaultValue={overview.notes} size="small" variant="outlined"
          onChange={(e) =>  overview.notes = e.target.value}
          onKeyPress={(e) => {
            console.log(`Pressed keyCode ${e.key}`);         
            if (e.key === 'Enter') {
            //  console.log(overview.notes);
               handleNotes(overview, overview.notes )
            }
          }}
          />
     
          
          // overview.notes,


        // desiredPositionFit: <SoftProgress value2={overview.desired_fit[0]} value={overview.desired_fit[1] * 100} variant="gradient" label />,
        // bestPositionFit: <SoftProgress value2={overview.best_fit[0]} value={overview.best_fit[1] * 100} variant="gradient" label />,
        // sector: <SoftProgress value={overview.sector_fit * 100} value3="100px" color="primary" variant="gradient" label />,
        // company_fit: <SoftProgress value={overview.company_fit * 100} value3="100px" variant="gradient" color="primary" label />,
        // // add_interview: <SoftButton variant="gradient"><FaRegCalendarPlus /></SoftButton>,


      }));

      const overviewdataChildren =  data.filter(overview => overview.is_control_sample === true).map((overview, index) => ({

        // info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(overview.name, overview.desired_fit[0])}><FaInfo /></SoftButton>,
        username: overview.username,   
        has_adhd: 
        <div
                key={`patient-${index}`}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
        <FormControlLabel control={<Checkbox color="primary"
        defaultChecked={overview.has_adhd} 
        onClick={() => handleCheckedADHD(overview, overview.has_adhd)}
         style={{ border: '2px solid #5e6063', padding: '2px' }} />}
                    label={overview.has_adhd}
                  />
                  </div>
          ,
          has_hyperactivity:   <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary" 
  defaultChecked={overview.has_hyperactivity} 
  onClick={() => handleCheckedHyper(overview, overview.has_hyperactivity)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_hyperactivity}
            />
            </div>,  

          has_add:  <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary"
  defaultChecked={overview.has_add} 
  onClick={() => handleCheckedADD(overview, overview.has_add)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_add}
            />
            </div>
            ,              
          has_autism: 
           <div
          key={`patient-${index}`}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >        
  <FormControlLabel control={<Checkbox color="primary"  
  defaultChecked={overview.has_autism} 
  onClick={() => handleCheckedAutism(overview, overview.has_autism)}
   style={{ border: '2px solid #5e6063', padding: '2px' }} />}
              label={overview.has_autism}
            />
            </div>,  

          notes: <TextField id="outlined-basic" 
          label="Notes" defaultValue={overview.notes} size="small" variant="outlined"
          onChange={(e) =>  overview.notes = e.target.value}
          onKeyPress={(e) => {
            console.log(`Pressed keyCode ${e.key}`);         
            if (e.key === 'Enter') {
            //  console.log(overview.notes);
               handleNotes(overview, overview.notes )
            }
          }}
          />
     
          
          // overview.notes,


        // desiredPositionFit: <SoftProgress value2={overview.desired_fit[0]} value={overview.desired_fit[1] * 100} variant="gradient" label />,
        // bestPositionFit: <SoftProgress value2={overview.best_fit[0]} value={overview.best_fit[1] * 100} variant="gradient" label />,
        // sector: <SoftProgress value={overview.sector_fit * 100} value3="100px" color="primary" variant="gradient" label />,
        // company_fit: <SoftProgress value={overview.company_fit * 100} value3="100px" variant="gradient" color="primary" label />,
        // // add_interview: <SoftButton variant="gradient"><FaRegCalendarPlus /></SoftButton>,


      }));


      setoverviewFit(overviewdata);
      setCandidateDetails(overviewdataChildren);


    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const fetchEmployee = async () => {
    try {
      const response = await apiEmployeeDetails.get('?company_id=' + cid);
      const data = response.data.employees;
      const empldata = data.map(empl => ({
        info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(empl.name, empl.position)}><FaInfo /></SoftButton>,
        name: empl.name,
        sector: "IT", // Assuming all employees are in the IT sector
        position: empl.position,
        email: empl.email,
        phone: empl.phone || "N/A", // Display "N/A" if phone is null or empty
        key: empl.key
      }));
      setEmployeeDetails(empldata);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const fetchCandidate = async () => {
    try {
      const response = await apiCandidateDetails.get('?company_id=' + cid);
      const data = response.data.candidates;
      const candata = data.map(can => ({
        info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(can.name, can.position)}><FaInfo /></SoftButton>,
        name: can.name,
        desiredPosition: can.position,
        email: can.email,
        phone: can.phone || "N/A", // Display "N/A" if phone is null or empty 
        key: can.key,
        // add_interview: <SoftButton variant="gradient"><FaRegCalendarPlus /></SoftButton> ,
      }));
      setCandidateDetails(candata);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [selectedD, setSelectedselectedD] = useState("");

  const [selected, setSelected] = useState("");

  const labelRefs = useRef([]);

  const handleOpenMail = (index) => {
    const labelText = labelRefs.current[index];
    // console.log(labelText);
    const subject = "Your Subject Here";
    const body = "The key to be sent in order to have access to MindEscape is: \n" + labelText;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };


  const [valueTab, setValueTab] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    // if (newValue == 2) {
    //   fetchCandidate();
    // } else if (newValue == 3) {
    //   fetchEmployee();
    // }
  };



  const componentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => {
      const content = componentPDF.current;
      const container = document.createElement('div');

      // Create company name element
      const companyName = document.createElement('div');
      companyName.textContent = `Company Name: ${company}`;
      companyName.style.float = 'left';
      companyName.style.fontWeight = 'bold';
      companyName.style.marginRight = '10px';
      companyName.style.fontSize = '15px'


      // Create employee name element
      const employeeName = document.createElement('div');
      employeeName.textContent = `Employee Name:  ${username}`;
      employeeName.style.float = 'right';
      employeeName.style.fontWeight = 'bold';
      employeeName.style.marginLeft = '10px';
      employeeName.style.fontSize = '15px'

      // Append the date text to the container
      const dateText = document.createElement('div');
      dateText.textContent = `Data Saved on: ${new Date().toLocaleString()}`;
      dateText.style.fontSize = '15px';
      dateText.style.float = 'right';
      dateText.style.marginLeft = '20px';

      // Create a clear fix element
      const clearFix = document.createElement('div');
      clearFix.style.clear = 'both';

      const informationText = document.createElement('div');
      informationText.textContent = componentPDF.current.dataset.information;
      informationText.style.fontWeight = 'bold';
      informationText.style.fontFamily = "sans-serif";

      // Append company and employee name to container
      container.appendChild(companyName);
      container.appendChild(employeeName);

      container.appendChild(clearFix);
      container.appendChild(dateText);

      container.appendChild(informationText);

      // Append the content to the container
      container.appendChild(content.cloneNode(true));

      return container;
    },
    documentTitle: 'Informations',
    pageStyle: `
      @page {
        size: A2 landscape; 
        margin: 100%; 
      }
      @media print {
        body {
          margin: 0;
        }
      }
    `,
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.style.overflow = isPopupOpen ? 'auto' : 'hidden';
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflow = 'auto';
  }

  const handleChecked = async (patient, value, label) => {

    // try {

      // baseURL:'https://adhd-63e54587a1ab.herokuapp.com/api/adhd/serial-keys/'  
      // Assuming apiSerialKeys is an Axios instance or similar
      // const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/serial-keys/" +
      //   candidateKey + '/?company_id=' + cid + '&given=true');


      // console.log('Data saved successfully:', response.data);
    // } catch (error) {
    //   console.error('Error saving data:', error);
    // }
  };

  const handleCheckedADHD = async (patient, value) => {
    patient.has_adhd = !value;
    // console.log(patient);
    try {
       const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/users/" +
       patient.id + '/', patient);
        
      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving data:', error);
    }

  }

  const handleCheckedADD = async (patient, value) => {
    patient.has_add = !value;
    // console.log(patient);
    try {
      const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/users/" +
      patient.id + '/', patient);
       
     console.log('Data saved successfully:', response.data);
   } catch (error) {
     console.error('Error saving data:', error);
   }
  }

  const handleCheckedAutism = async (patient, value) => {
    patient.has_autism = !value;
    // console.log(patient);
    try {
      const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/users/" +
      patient.id + '/', patient);
       
     console.log('Data saved successfully:', response.data);
   } catch (error) {
     console.error('Error saving data:', error);
   }
  }

  const handleCheckedHyper = async (patient, value) => {
    patient.has_hyperactivity = !value;
    // console.log(patient);
    try {
      const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/users/" +
      patient.id + '/', patient);
       
     console.log('Data saved successfully:', response.data);
   } catch (error) {
     console.error('Error saving data:', error);
   }
  }

  
  const handleNotes = async (patient, value) => {

    console.log(patient);
    patient.notes = value;
    // console.log(patient);
    try {
      const response = await axios.put("https://adhd-63e54587a1ab.herokuapp.com/api/adhd/users/" +
      patient.id + '/', patient);
       
     console.log('Data saved successfully:', response.data);
   } catch (error) {
     console.error('Error saving data:', error);
   }
  }
  

  const handleCandidateClick = (candidateName, position) => {
    setSelectedCandidate(candidateName);
    setSelectedselectedD(position);
    togglePopup();
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <SoftBox py={3}>
        <Grid container spacing={3} columnSpacing={1}>
        <Grid container xs={4} spacing={2} columnSpacing={1} >
            <Grid item xs={11} >
                <MiniStatisticsCard2
                  title={{ text: "Game stats" }}
                  title1={{ text: "Escaped" }}
                  title2={{ text: "Failed" }}
                  count1={Math.round(gameStats.escaped * 100 * 10) / 10}
                  count2={Math.round(gameStats.not_escaped * 100 * 10) / 10}
                />
              

            </Grid>
            <Grid item xs={11} >
              {gameStats.rating &&
                <MiniStatisticsCard3
                  title={{ text: "Ratings" }}
                  count={gameStats.rating}
                />}
            </Grid>
          </Grid>

          <Grid container xs={4} spacing={3} columnSpacing={1} >
            <Grid item xs={11} >
                <Sectors
                  title={{ text: "CLINICS" }}
                  count={baseStats.sectors}
                />
            </Grid>
            <Grid item xs={11}  >
                <MiniStatisticsCard
                  title={{ text: "DIAGNOSED" }}
                  count={baseStats.employees}
                  icon={{ color: "primary", component: "public" }}
                />
            </Grid>
          </Grid>

          <Grid container xs={4} spacing={3} >
            <Grid item xs={11} >
              <Position
                title={{ text: "AVAILIABLE KEYS ADULTS" }}
                count={baseStats.open_positions}
              />
            </Grid>
            <Grid item xs={11} >
                <MiniStatisticsCard
                  title={{ text: "UNDIAGNOSED ADULTS" }}
                  count={baseStats.applicants}
                  icon={{ color: "info", component: "public" }}
                />
            </Grid>
          </Grid>

          <Grid container xs={4} spacing={3} >
            <Grid item xs={11} >
              <Position
                title={{ text: "AVAILIABLE KEYS KIDS" }}
                count={baseStats.open_positions}
              />
            </Grid>
            <Grid item xs={11} >
                <MiniStatisticsCard
                  title={{ text: "UNDIAGNOSED KIDS" }}
                  count={baseStats.applicants}
                  icon={{ color: "info", component: "public" }}
                />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox > */}

      <SoftBox >
        <Grid container>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={valueTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Adults" value="1" />
                  <Tab label="Children" value="2" />
                  {/* <Tab label="Employees details" value="3" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                {overviewFit &&
                  <Table1
                    columns={[
                      { name: "username", label: "Username", align: "center" },
                      { name: "has_adhd", label: "adhd", align: "center" },
                      { name: "has_hyperactivity", label: "hyperactivity", align: "center" },
                      { name: "has_add", label: "add", align: "center" },
                      { name: "has_autism", label: "autism", align: "center" },
                      { name: "notes", label: "notes", align: "center" },

                      // { name: "bestPositionFit", label: "add", align: "center" },
                      // { name: "sector", label: "autism", align: "center", width: "200px" },
                      // { name: "company_fit", label: "company fit", align: "center" },
                      // { name: "add_interview", label: "add interview", align: "center" },
                    ]}
                    rows={overviewFit}
                  />
                }
                {isPopupOpen && (<CandidateInfo closePopup={closePopup} name={selectedCandidate} position={selectedD} />)}
              </TabPanel>

              <TabPanel value="2">
                {candidateDetails &&
                  <Table1
                    columns={[
                      { name: "username", label: "Username", align: "center" },
                      { name: "has_adhd", label: "adhd", align: "center" },
                      { name: "has_hyperactivity", label: "hyperactivity", align: "center" },
                      { name: "has_add", label: "add", align: "center" },
                      { name: "has_autism", label: "autism", align: "center" },
                      { name: "notes", label: "notes", align: "center" },
                      // { name: "info", label: "info", align: "center" },
                      // { name: "name", label: "name", align: "center" },
                      // { name: "desiredPosition", label: "Desired position fit", align: "center" },
                      // { name: "email", label: "email", align: "center" },
                      // { name: "phone", label: "phone", align: "center" },
                      // { name: "key", label: "key", align: "center" },
                      // { name: "add_interview",label: "add interview",  align: "center" }

                    ]}
                    rows={candidateDetails}
                  />}
                {isPopupOpen && (<CandidateInfo closePopup={closePopup} name={selectedCandidate} position={selectedD} />)}

              </TabPanel>

              {/* <TabPanel value="3">
                {employeeDetails &&
                  <Table1
                    columns={[
                      { name: "info", label: "info", align: "center" },
                      { name: "name", label: "name", align: "center" },
                      { name: "sector", label: "sector", align: "center" },
                      { name: "position", label: "position", align: "center" },
                      { name: "email", label: "email", align: "center" },
                      { name: "phone", label: "phone", align: "center" },
                      { name: "key", label: "key", align: "center" },

                    ]}
                    rows={employeeDetails}
                  />
                }
                {isPopupOpen && (<CandidateInfo closePopup={closePopup} name={selectedCandidate} position={selectedD} />)}
              </TabPanel> */}
            </TabContext>
          </Box>
        </Grid>
      </SoftBox>


      {/* <SoftBox mb={3} >
        <Grid container spacing={8}>
          {/* <Grid item xs={5}>
            <h4>Open positions info</h4>
            {rows &&
              <Table1
                columns={[
                  { name: "positions", label: "positions", align: "center" },
                  { name: "numberOfApplicants", label: "NO. applicants", align: "center" },
                  { name: "candidates", label: "candidates", align: "center" },
                  { name: "employees", label: "employees", align: "center" },

                ]}
                rows={rows}
              />}
          </Grid> */}

{/* <Grid item xs={6}>
            <h4>Serial Keys - Adults</h4>
            {keys &&
              <Table1
                columns={[

                  { name: "unused_key_candidate", label: "send unused key to Undiagnosed", align: "center" },
                  { name: "unused_key_employee", label: "send unused key to diagnosed", align: "center" },

                ]}

                rows={keys}
              />}
          </Grid>

          <Grid item xs={6}>
            <h4>Serial Keys - Kids</h4>
            {keys &&
              <Table1
                columns={[

                  { name: "unused_key_candidate", label: "send unused key to Undiagnosed", align: "center" },
                  { name: "unused_key_employee", label: "send unused key to diagnosed", align: "center" },

                ]}

                rows={keys}
              />}
          </Grid>
        </Grid>

      </SoftBox> */} 

      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default ADHD;
