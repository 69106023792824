import React from "react";
// import { Route } from "react-router-dom";
// import { useAuth } from "auth-context/auth.context";
// import { useNavigate } from "react-router-dom";
// import SweetAlert from "react-bootstrap-sweetalert";

import {
    Outlet,
    Navigate
} from 'react-router-dom'

const ProtectedRoute = () => {
    let auth = { 'token': true }
    return (
        auth.token ?
            <Outlet /> :
            <Navigate to="/authentication/sign-in" />
    )
}

export default ProtectedRoute