import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Soft UI Dashboard React examples
import './CandidateInfo.css';
import Grid from "@mui/material/Grid";

import { MdOutlineClose } from "react-icons/md"

// Soft UI Dashboard React examples
import 'reactjs-popup/dist/index.css';
import SoftBox from 'components/SoftBox';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, RadialBarChart, RadialBar, Tooltip, Legend } from 'recharts';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, ErrorBar } from 'recharts';

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { useRef } from "react";
import apiProfile from 'api/Profile';
import { FaFileDownload } from "react-icons/fa";
import SoftButton from "components/SoftButton";
import { useSoftUIController } from "context";
import { useReactToPrint } from "react-to-print";
import SoftProgress from "components/SoftProgress";
import ProgressBar from "@ramonak/react-progress-bar";


function CandidateInfo({ closePopup, name, position }) {
  const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  let { company } = localStorage.getItem("companyName");
  let { username } = localStorage.getItem("username");

  const componentPDF = useRef();
  // const componentPDF1 = useRef();


  const generatePDF = useReactToPrint({
    content: () => {
      const content = componentPDF.current;
      // const content1 = componentPDF1.current;

      const container = document.createElement('div');

      // Create company name element
      const companyName = document.createElement('div');
      companyName.textContent = `Company Name: ${company}`;
      companyName.style.float = 'left';
      companyName.style.fontWeight = 'bold';
      companyName.style.marginRight = '10px';
      companyName.style.fontSize = '15px'
      // Create employee name element
      // const employeeName = document.createElement('div');
      // employeeName.textContent = `Employee Name:  ${username}`;
      // employeeName.style.float = 'right';
      // employeeName.style.fontWeight = 'bold';
      // employeeName.style.marginLeft = '10px';
      // employeeName.style.fontSize = '15px'

      const can = document.createElement('div');
      can.textContent = `Candidate: ${name}`;
      can.style.float = 'right';
      can.style.fontWeight = 'bold';
      can.style.marginRight = '10px';
      can.style.fontSize = '15px'
      // Append the date text to the container
      const dateText = document.createElement('div');
      dateText.textContent = `Data Saved on: ${new Date().toLocaleString()}`;
      dateText.style.fontSize = '15px';
      dateText.style.float = 'right';
      dateText.style.marginLeft = '20px';

      // Create a clear fix element
      const clearFix = document.createElement('div');
      clearFix.style.clear = 'both';


      container.appendChild(companyName);
      // container.appendChild(employeeName);

      container.appendChild(can);

      container.appendChild(clearFix);


      // Append the content to the container
      container.appendChild(content.cloneNode(true));
      container.appendChild(dateText);

      // container.appendChild(content1.cloneNode(true));

      return container;
    },
    documentTitle: 'Informations',
    pageStyle: `
      @page {
        size: A2 landscape; 
        margin: 100%; 
      }
      @media print {
        body {
          margin: 0;
        }
      }
    `,
  });

  const [valueTab, setValueTab] = React.useState("1");
  const [profile, setProfiles] = useState([]);


  let popupRef = useRef();

  useEffect(() => {
    cid  = localStorage.getItem("cid");
    company = localStorage.getItem("companyName");
    username = localStorage.getItem("username");
    fetchPr();
  }, [])

  // const cid1 = 6;
  const fetchPr = async () => {
    try {
      const response = await apiProfile.get('?user_id=' + cid);
      setProfiles(response.data);

    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }



  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };


  function checkClickOutside(e) {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      closePopup();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside);
    return () => {
      document.removeEventListener('mousedown', checkClickOutside);
    };
  }, [closePopup])


  if (!profile || !profile.personality) {
    return;
  }

console.log(profile);

  const facesets = profile.facesets;

  
  const behaviors = profile.behaviour;

  const radarData = {
    "2": [
      { name: 'Imagination', x: facesets.imagination },
      { name: 'Artistic Interests', x: facesets.artistic_interests },
      { name: 'Depth of Emotions', x: facesets.depth_of_emotions },
      { name: 'Willingness to Experiment', x: facesets.willingness_to_experiment },
      { name: 'Intellectual Curiosity', x: facesets.intellectual_curiosity },
      { name: 'Tolerance for Diversity', x: facesets.tolerance_for_diversity }
    ],
    "3": [
      { name: 'Sense of Competence', x: facesets.sense_of_competence },
      { name: 'Orderliness', x: facesets.orderliness },
      { name: 'Sense of Responsibility', x: facesets.sense_of_responsibility },
      { name: 'Achievement Striving', x: facesets.achievement_striving },
      { name: 'Self-Discipline', x: facesets.self_discipline },
      { name: 'Deliberateness', x: facesets.deliberateness }
    ],
    "4": [
      { name: 'Warmth', x: facesets.warmth },
      { name: 'Gregariousness', x: facesets.gregariousness },
      { name: 'Assertiveness', x: facesets.assertiveness },
      { name: 'Activity Level', x: facesets.activity_level },
      { name: 'Excitement-Seeking', x: facesets.excitement_seeking },
      { name: 'Positive Emotions', x: facesets.positive_emotions }
    ],
    "5": [
      { name: 'Trust in others', x: facesets.trust_in_others },
      { name: 'Sincerity', x: facesets.sincerity },
      { name: 'Altruism', x: facesets.altruism },
      { name: 'Compliance', x: facesets.compliance },
      { name: 'Modesty', x: facesets.modesty },
      { name: 'Sympathy', x: facesets.sympathy }
    ],
    "6": [
      { name: 'Anxiety', x: facesets.anxiety },
      { name: 'Angry Hostility', x: facesets.angry_hostility },
      { name: 'Moodiness/Contentment', x: facesets.moodiness_contentment },
      { name: 'Self-Consciousness', x: facesets.self_consciousness },
      { name: 'Self-Indulgence', x: facesets.self_indulgence },
      { name: 'Sensitivity to Stress', x: facesets.sensitivity_to_stress }
    ]
  };

  
  const dataOB = [

    {
      "name1": "low exploration",
      "name2": "high exploration",
      "uv": behaviors.exploration,
      "fill": "#317e89"
    },

  ]


  const dataRDC1 = [

    {
      "name1": "low imagination",
      "name2": "high imagination",
      "uv": facesets.imagination-50,
      "fill": "#317e89"
    },
    {
      "name1": "low artistic interests",
      "name2": "high artistic interests",
      "uv": facesets.artistic_interests-50,
      "fill": "#3592a0"
    },
    {
      "name1": "low depth of emotions",
      "name2": "high depth of emotions",
      "uv": facesets.depth_of_emotions-50,
      "fill": "#3ba7ba"
    },
    {
      "name1": "low willingness to experiment",
      "name2": "high willingness to experiment",
      "uv": facesets.willingness_to_experiment-50,
      "fill": "#40b7cd"
    },
    {
      "name1": "low intellectual curiosity",
      "name2": "high intellectual curiosity",
      "uv": facesets.intellectual_curiosity-50,
      "fill": "#48c1d4"
    },
    {
      "name1": "low tolerance for diversity",
      "name2": "high tolerance for diversity",
      "uv": facesets.tolerance_for_diversity-50,
      "fill": "#5eccdc"
    },
  ]

  
  const dataCB = [

    {
      "name1": "low impatience",
      "name2": "high impatience",
      "uv": behaviors.impatience,
      "fill": "#922374"
    },
    {
      "name1": "low rightpref",
      "name2": "high rightpref",
      "uv": behaviors.rightpref * 0.3,
      "fill":  "#8f156a"
    },
    {
      "name1": "low panic",
      "name2": "high panic",
      "uv": behaviors.panic*0.5,
      "fill":  "#a0467f"
    },

  ]


  const dataRDC2 = [

    {
      "name1": "low deliberateness",
      "name2": "high deliberateness",
      "uv": facesets.deliberateness-50,
      "fill": "#922374"
    },
    {
      "name1": "low sense of competence",
      "name2": "high  sense of competence",
      "uv": facesets.sense_of_competence-50,
      "fill": "#8f156a"
    },
    {
      "name1": "low orderliness",
      "name2": "high orderliness",
      "uv": facesets.orderliness-50,
      "fill": "#a0467f"
    },
    {
      "name1": "low sense of responsibility",
      "name2": "high sense of responsibility",
      "uv": facesets.sense_of_responsibility-50,
      "fill": "#ba7ba1"
    },
    {
      "name1": "low achievement striving",
      "name2": "high achievement striving",
      "uv":  facesets.achievement_striving-50,
      "fill": "#d6afc6"
    },
    {
      "name1": "low self-discipline",
      "name2": "high self-discipline",
      "uv": facesets.self_discipline-50,
      "fill": "#eedfe7"
    },

  ]

  
  const dataEB = [

    {
      "name1": "low leadership",
      "name2": "high leadership",
      "uv": behaviors.leadership*0.5,
      "fill": "#00bf34"
    },
    {
      "name1": "low impatience",
      "name2": "high impatience",
      "uv": behaviors.impatience,
      "fill": "#00ca58"
    },
    {
      "name1": "low walkspeed",
      "name2": "high walkspeed",
      "uv":  behaviors.walkspeed_m,
      "fill": "#00d56f"
    },  
  ]

  const dataRDC3 = [

    {
      "name1": "low warmth",
      "name2": "high warmth",
      "uv": facesets.warmth-50,
      "fill": "#00bf34"
    },
    {
      "name1": "low gregariousness",
      "name2": "high gregariousness",
      "uv": facesets.gregariousness-50,
      "fill": "#00ca58"
    },
    {
      "name1": "low assertiveness",
      "name2": "high assertiveness",
      "uv":  facesets.assertiveness-50,
      "fill": "#00d56f"
    },
    {
      "name1": "low activity level",
      "name2": "high activity level",
      "uv":  facesets.activity_level-50,
      "fill": "#32de8b"
    },
    {
      "name1": "low excitement-seeking",
      "name2": "high excitement-seeking",
      "uv": facesets.excitement_seeking-50,
      "fill": "#83e7ae"
    },
    {
      "name1": "low positive emotions",
      "name2": "high positive emotions",
      "uv": facesets.positive_emotions-50,
      "fill": "#b7f1ce"
    },
  ]


  const dataAB = [
    {
      "name1": "low trust in others",
      "name2": "high trust in others",
      "uv":  behaviors.impatience*0.5,
      "fill": "#d85c1f"
    },
    {
      "name1": "low sincerity",
      "name2": "high sincerity",
      "uv": behaviors.rightpref*0.5,
      "fill": "#e97c2f"
    },   
  ]

  const dataRDC4 = [
    {
      "name1": "low trust in others",
      "name2": "high trust in others",
      "uv":  facesets.trust_in_others-50,
      "fill": "#d85c1f"
    },
    {
      "name1": "low sincerity",
      "name2": "high sincerity",
      "uv": facesets.sincerity-50,
      "fill": "#e97c2f"
    },
    {
      "name1": "low altruism",
      "name2": "high altruism",
      "uv": facesets.altruism-50,
      "fill": "#f1a23a"
    },
    {
      "name1": "low compliance",
      "name2": "high compliance",
      "uv": facesets.compliance-50,
      "fill": "#f5b941"
    },
    {
      "name1": "low modesty",
      "name2": "high modesty",
      "uv": facesets.modesty-50,
      "fill": "#f9d048"
    },
    {
      "name1": "low sympathy",
      "name2": "high sympathy",
      "uv": facesets.sympathy-50,
      "fill": "#fae04a"
    },
  ]

  const dataNB = [
    {
      "name1": "low trust in others",
      "name2": "high trust in others",
      "uv":  behaviors.leadership*0.5,
      "fill": "#b22126"
    },
    {
      "name1": "low sincerity",
      "name2": "high sincerity",
      "uv": behaviors.panic*0.5,
      "fill": "#ce3238"
    },   
  ]

  const dataRDC5 = [

    {
      "name1": "low anxiety",
      "name2": "high anxiety",
      "uv": facesets.anxiety-50,
      "fill": "#b22126"
    },
    {
      "name1": "low moodiness/contentment",
      "name2": "high moodiness/contentment",
      "uv": facesets.moodiness_contentment-50,
      "fill": "#ce3238"
    },
    {
      "name1": "low self-consciousness",
      "name2": "high self-consciousness",
      "uv": facesets.self_consciousness-50,
      "fill": "#e13b3e"
    },
    {
      "name1": "low self-indulgence",
      "name2": "high self-indulgence",
      "uv": facesets.self_indulgence-50,
      "fill": "#f04440"
    },
    {
      "name1": "low sensitivity to stress",
      "name2": "high sensitivity to stress",
      "uv": facesets.sensitivity_to_stress-50,
      "fill": "#ea5458"
    },
    {
      "name1": "low angry hostility",
      "name2": "high angry hostility",
      "uv": facesets.angry_hostility-50,
      "fill": "#e07479"
    },
  ]


  const { openness, conscientiousness, extroversion, agreeableness, neuroticism } = profile.personality;
  const data = [
    { name: 'Openness', x: (openness/8.2) * 100 },
    { name: 'Conscientiousness', x: (conscientiousness/1.447) * 100 },
    { name: 'Extraversion', x: (extroversion/10.22) * 100 },
    { name: 'Agreeableness', x: (agreeableness/13.29) * 100 },
    { name: 'Neuroticism', x: (neuroticism/6.06) * 100 }
  ]


  return (
    <div className="big-popup" ref={popupRef}>
      <div className="big-popup-content">
        <MdOutlineClose className="close-button" onClick={closePopup} />
        <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500' }}> {name} <br /> {position}  </p>
        <SoftButton variant="gradient" style={{ float: 'right' }} iconOnly={false} onClick={generatePDF}>
          Print <FaFileDownload />
        </SoftButton>
        <br></br>
        <Box ref={componentPDF}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <RadarChart
              height={550}
              width={550}
              outerRadius="50%"
              data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis
                angle={0}
                domain={[-50, 50]} />
              <Radar dataKey="x" stroke="black" fillOpacity={0} />
            </RadarChart>
          </Box>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Openness</p>
            <ProgressBar completed={(openness/8.2) * 100} bgColor='#3B8EA5' />
            {/* <SoftProgress value='50' color='#3B8EA5' label /> */}
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Openness to Experience describes a dimension of cognitive style that distinguishes imaginative, creative people from down-to-earth, conventional people. Open people are intellectually curious, appreciative of art and sensitive to beauty. They tend to be, compared to closed people, more aware of their feelings. They tend to think and act in individualistic and nonconforming ways.</p>
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataOB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginRight: '10px' }}>Conscientiousness</p>
            <ProgressBar completed={ (conscientiousness/1.447) * 100} bgColor='#BA7BA1' />
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Conscientiousness concerns the way in which we control, regulate and direct our impulses. Impulses are not inherently bad; occasionally time constraints require a snap decision and acting on our first impulse can be an effective response. Also, in times of play rather than work, acting spontaneously and impulsively can be fun. Impulsive individuals can be seen by others as colourful, fun-to-be-with and zany.</p>
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataCB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Extraversion</p>
            <ProgressBar completed={(extroversion/10.22) * 100} bgColor='#32DE8A' />
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Extraversion is marked by pronounced engagement with the external world. Extraverts enjoy being with people, are full of energy and often experience positive emotions. They tend to be enthusiastic and action-oriented individuals who are likely to say &quot;Yes!&quot; or &quot;Let&apos; s go!&quot; to opportunities for excitement. In groups they like to talk, assert themselves and draw attention to themselves.</p>
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataEB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginRight: '10px' }}>Agreeableness</p>
            <ProgressBar completed={(agreeableness/13.29) * 100} bgColor='#F5B841' />
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Agreeableness reflects individual differences in concern about co-operation and social harmony. Agreeable individuals value getting along with others. They are therefore considerate, friendly, generous, helpful and willing to compromise their interests with others&apos;. Agreeable people also have an optimistic view of human nature. They believe people are basically honest, decent and trustworthy.</p>
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataAB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div>
            <p style={{ textAlign: 'center', marginBottom: '5px', marginLeft: '10px' }}>Neuroticism</p>
            <ProgressBar completed={(neuroticism/6.06)  * 100} bgColor='#B22125' />
            <p style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>People high in Natural Reactions are emotionally reactive. They respond emotionally to events that do not affect a lot of people and their reactions tend to be more intense. They are more likely to interpret ordinary situations as threatening and minor frustrations as hopelessly difficult. Their negative emotional reactions tend to persist for unusually long periods of time.  These problems can diminish a person&apos;s ability to think clearly, make decisions and cope effectively with stress.</p>
            <h4 style={{ margin: 0, fontSize: '16px', textAlign: 'center' }}>Facets</h4>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={dataNB} margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[-50, 50]} />
                  <YAxis dataKey="name1" type="category" />
                  <YAxis yAxisId="right" orientation="right" dataKey="name2" type="category" />
                  <Bar dataKey="uv" stackId="a" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <br />
          <div>
          </div>
        </Box>
      </div>
    </div >


  );
}

// Define propTypes for the component
CandidateInfo.propTypes = {
  name: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};


export default CandidateInfo;
