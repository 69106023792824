import { Grid, Typography } from '@material-ui/core';
import { useState, useRef, useEffect } from "react";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
import { FaCopy } from "react-icons/fa";
// import { IoMail } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Progress from "examples/Cards/StatisticsCards/Progress";
// import MiniStatisticsCard2 from "examples/Cards/StatisticsCards/MiniStatisticsCard2";
import MiniStatisticsCard3 from "examples/Cards/StatisticsCards/MiniStatisticsCard3";
// import Position from "examples/Cards/StatisticsCards/Position";
import Classes from "examples/Cards/StatisticsCards/Classes";
import axios from 'axios'

// Soft UI Dashboard React base styles
// import TabPanel from "@mui/lab/TabPanel"

// Soft UI Dashboard React examples
import Table1 from "examples/Tables/Table1";
import { FaInfo } from "react-icons/fa";

// import { Tab } from "@mui/material";
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";

import 'reactjs-popup/dist/index.css';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CandidateInfo from "./CandidateInfo";
import EmployeeInfoDialog from "./EmployeeInfoDialog";
import CandidateInfoDialog from "./CandidateInfoDialog";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import apiBaseStats from 'api/BaseStats';
import apiSerialKeys from 'api/SerialKeys';
// import apiEmployeeDetails from 'api/EmployeeDetails';
// import apiCandidateDetails from 'api/CandidateDetails';
import apiOverviewFit from 'api/OverviewFit';
import apiOpenPositions from 'api/OpenPositions';
import apiGameMetrics from 'api/GameMetrics';

function DashboardStudent() {
  const [gameStats, setGameStats] = useState([]);
  const [baseStats, setBaseStats] = useState([]);
  const [keys, setSerialKeys] = useState([]);
  // const [employeeDetails, setEmployeeDetails] = useState([]);
  // const [candidateDetails, setCandidateDetails] = useState([]);
  const [overviewFit, setOverviewFit] = useState([]);
  const [rows, setRows] = useState([]);
  const [idUser, selectedIdUser] = useState("");
  const location = useLocation();
  // const { state } = location; // Destructure the state object from location

  // Check if state and cid exist before destructuring
  let cid = localStorage.getItem("cid")

  useEffect(() => {

    if (cid) {
      fetchAllData();
    }
  }, []);

  const fetchAllData = async () => {
    try {
      await Promise.all([
        fetchGame(),
        fetchBaseStats(),
        fetchSerialKeys(),
        fetchOverviewFit(),
        fetchRows(),
        fetchEmployee(),
        fetchCandidate(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchGame = async () => {
    try {
      const response = await apiGameMetrics.get('?company_id=' + cid);
      setGameStats(response.data);
    } catch (error) {
      console.error('Error fetching gameStats:', error);
    }
  };

  const fetchBaseStats = async () => {
    try {
      const response = await apiBaseStats.get('?company_id=' + cid);
      setBaseStats(response.data);
    } catch (error) {
      console.error('Error fetching baseStats:', error);
    }
  };

  const fetchSerialKeys = async () => {
    try {
      const response = await apiSerialKeys.get('?company_id=' + cid);
      const candidateKeys = response.data.unused_candidate_keys || [];
      const keys = candidateKeys.map((candidateKey, index) => {
        return {
          unused_key_candidate: (
            <div
              key={`candidate-${index}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {candidateKey.key ? (
                <>

                  <FormControlLabel
                    control={<Checkbox color="secondary" disabled={candidateKey.given} defaultChecked={candidateKey.given} onClick={() => handleChecked(candidateKey.key)} style={{ border: '2px solid #5e6063', padding: '2px' }} />}
                    label={candidateKey.key}
                  />
                  {/* <SoftButton onClick={handleOpenMail} variant="gradient">
                    <IoMail />
                  </SoftButton> */}

                  <CopyToClipboard text={candidateKey.key} >
                    <SoftButton variant="gradient" disabled={candidateKey.given}>   <FaCopy /> </SoftButton>
                  </CopyToClipboard>
                </>
              ) : (
                <span>No Candidate Key</span>
              )}
            </div>
          )
        };
      });
      setSerialKeys(keys);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };

  const fetchOverviewFit = async () => {
    try {
      const response = await apiOverviewFit.get('?company_id=' + cid);
      const data = response.data.candidates;
      const overviewdata = data.map(overview => ({
        info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(overview.id,overview.name, overview.desired_fit[0])}><FaInfo /></SoftButton>,
        candidates: overview.name,
        desiredPositionFit: <SoftProgress value2={overview.desired_fit[0]} value={overview.desired_fit[1] * 100} variant="gradient" label />,
        bestPositionFit: <SoftProgress value2={overview.best_fit[0]} value={overview.best_fit[1] * 100} variant="gradient" label />,
        sector: <SoftProgress value={overview.sector_fit * 100} value3="100px" color="primary" variant="gradient" label />,
        company_fit: <SoftProgress value={overview.company_fit * 100} value3="100px" variant="gradient" color="primary" label />,
      }));
      setOverviewFit(overviewdata);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }

  const fetchRows = async () => {
    try {
      const response = await apiOpenPositions.get('?company_id=' + cid);
      const data = response.data || [];

      const formattedRows = data.map(job => ({
        positions: job.position,
        numberOfApplicants: job.applicants,
        employees: (
          <EmployeeInfoDialog
            positionToShow={job.position}
            componentPDF={componentPDF}
            generatePDF={generatePDF}
          />
        ),
        candidates: (
          <CandidateInfoDialog
            positionToShow={job.position}
            componentPDF={componentPDF}
            generatePDF={generatePDF}
          />
        )
      }));

      setRows(formattedRows);
    } catch (error) {
      console.error('Error fetching rows:', error);
    }
  };

  // const fetchEmployee = async () => {
  //   try {
  //     const response = await apiEmployeeDetails.get('?company_id=' + cid);
  //     const data = response.data.employees || [];

  //     const formattedEmployees = data.map(employee => ({
  //       info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(employee.name, employee.position)}><FaInfo /></SoftButton>,
  //       name: employee.name,
  //       sector: "IT", // Assuming all employees are in the IT sector
  //       position: employee.position,
  //       email: employee.email,
  //       phone: employee.phone || "N/A",
  //       key: employee.key
  //     }));

  //     setEmployeeDetails(formattedEmployees);
  //   } catch (error) {
  //     console.error('Error fetching employeeDetails:', error);
  //   }
  // };

  // const fetchCandidate = async () => {
  //   try {
  //     const response = await apiCandidateDetails.get('?company_id=' + cid);
  //     const data = response.data.candidates || [];

  //     const formattedCandidates = data.map(candidate => ({
  //       info: <SoftButton variant="gradient" onClick={() => handleCandidateClick(candidate.name, candidate.position)}><FaInfo /></SoftButton>,
  //       name: candidate.name,
  //       desiredPosition: candidate.position,
  //       email: candidate.email,
  //       phone: candidate.phone || "N/A",
  //       key: candidate.key
  //     }));

  //     setCandidateDetails(formattedCandidates);
  //   } catch (error) {
  //     console.error('Error fetching candidateDetails:', error);
  //   }
  // };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [selectedD, setSelectedselectedD] = useState("");

  const [selected, setSelected] = useState("");

  const labelRefs = useRef([]);

  const handleOpenMail = (index) => {
    const labelText = labelRefs.current[index];
    // console.log(labelText);
    const subject = "Your Subject Here";
    const body = "The key to be sent in order to have access to MindEscape is: \n" + labelText;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };


  const [valueTab, setValueTab] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    if (newValue == 2) {
      fetchCandidate();
    } else if (newValue == 3) {
      fetchEmployee();
    }
  };


  const componentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => {
      const content = componentPDF.current;
      const container = document.createElement('div');

      // Create company name element
      const companyName = document.createElement('div');
      companyName.textContent = `Company Name: ${company}`;
      companyName.style.float = 'left';
      companyName.style.fontWeight = 'bold';
      companyName.style.marginRight = '10px';
      companyName.style.fontSize = '15px'


      // Create employee name element
      const employeeName = document.createElement('div');
      employeeName.textContent = `Employee Name:  ${username}`;
      employeeName.style.float = 'right';
      employeeName.style.fontWeight = 'bold';
      employeeName.style.marginLeft = '10px';
      employeeName.style.fontSize = '15px'

      // Append the date text to the container
      const dateText = document.createElement('div');
      dateText.textContent = `Data Saved on: ${new Date().toLocaleString()}`;
      dateText.style.fontSize = '15px';
      dateText.style.float = 'right';
      dateText.style.marginLeft = '20px';

      // Create a clear fix element
      const clearFix = document.createElement('div');
      clearFix.style.clear = 'both';

      const informationText = document.createElement('div');
      informationText.textContent = componentPDF.current.dataset.information;
      informationText.style.fontWeight = 'bold';
      informationText.style.fontFamily = "sans-serif";

      // Append company and employee name to container
      container.appendChild(companyName);
      container.appendChild(employeeName);

      container.appendChild(clearFix);
      container.appendChild(dateText);

      container.appendChild(informationText);

      // Append the content to the container
      container.appendChild(content.cloneNode(true));

      return container;
    },
    documentTitle: 'Informations',
    pageStyle: `
      @page {
        size: A2 landscape; 
        margin: 100%; 
      }
      @media print {
        body {
          margin: 0;
        }
      }
    `,
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.style.overflow = isPopupOpen ? 'auto' : 'hidden';
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflow = 'auto';
  }

  const handleChecked = async (candidateKey) => {

    // console.log(candidateKey);
    try {
      const response = await axios.put("https://backend2-eeedeecf102a.herokuapp.com/api/hr/serial-keys/" +
        candidateKey + '/?company_id=' + cid + '&given=true');
      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };


  const handleCandidateClick = (id,candidateName, position) => {
    selectedIdUser(id);
    setSelectedCandidate(candidateName);
    setSelectedselectedD(position);
    togglePopup();
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <Typography variant="h5" align="center">
              Game Stats
            </Typography>
          </Grid>
          <Grid item xs={4}  style={{ height: "80px" }}>
            <Progress
              title1={{ text: "Escaped" }}
              count1={Math.round(gameStats.escaped * 100 * 10) / 10}
            />
          </Grid>
          <Grid item xs={4}>
            <Progress
              title1={{ text: "Failed" }}
              count1={Math.round(gameStats.not_escaped * 100 * 10) / 10}
            />
          </Grid>
          {gameStats.rating && (
            <Grid item xs={4}>
              <MiniStatisticsCard3
                title={{ text: "Ratings" }}
                count={gameStats.rating}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Overview
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center" spacing={3}>
            <Grid item xs={3}>
              <Classes
                title={{ text: "CLASSES" }}
                count={baseStats.sectors}
              />
            </Grid>
            <Grid item xs={3}>
              <MiniStatisticsCard
                title={{ text: "STUDENTS" }}
                count={baseStats.applicants}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox >

      <SoftBox >
        <Grid container>
          <h4>Student Stats</h4>
          {overviewFit &&
            <Table1
              columns={[
                { name: "info", label: "info", align: "center" },
                { name: "candidates", label: "students", align: "center" },
                { name: "desiredPositionFit", label: "Desired position fit", align: "center" },
                { name: "bestPositionFit", label: "Best position fit", align: "center" },
                { name: "sector", label: "class fit", align: "center", width: "200px" },
              ]}
              rows={overviewFit}
            />
          }

          {isPopupOpen && (<CandidateInfo closePopup={closePopup} id={idUser} name={selectedCandidate} position={selectedD} />)}
        </Grid>
      </SoftBox>

      <br></br>
      <SoftBox mb={3} >
        <Grid container spacing={8}>
          <Grid item xs={5}>
            <h4>Open Jobs info</h4>
            {rows &&
              <Table1
                columns={[
                  { name: "positions", label: "positions", align: "center" },
                  { name: "candidates", label: "student", align: "center" },
                ]}
                rows={rows}
              />}
          </Grid>

          <Grid item xs={7}>
            <h4>Serial Keys - Unused</h4>
            {keys &&
              <Table1
                columns={[
                  { name: "unused_key_candidate", label: "send unused key to student", align: "center" },
                ]}

                rows={keys}
              />}
          </Grid>
        </Grid>
      </SoftBox>

      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default DashboardStudent;
