import PropTypes from 'prop-types';
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";
import { FaFileDownload } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from "examples/Tables/Table";
import { FaUserFriends } from "react-icons/fa";
import { useState, useEffect } from 'react';
import apiOpenPositions from 'api/OpenPositions';
import { useSoftUIController } from "context";




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '90%',
    borderRadius: "10px",
  },
}));


const EmployeeInfoDialog = ({ positionToShow, componentPDF, generatePDF }) => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);


  const [controller] = useSoftUIController();
  let { cid } = localStorage.getItem("cid");
  useEffect(() => {
    if (open) {
      fetchRows();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const fetchRows = async () => {
    try {
      cid = localStorage.getItem("cid");
      const response = await apiOpenPositions.get('?company_id=' + cid);
      const data = response.data;

      // Find the job position based on positionToShow
      const job = data.find(job => job.position === positionToShow);

      // Extract employees for the selected job position
      const formattedData = job ? job.employees.map(empl => ({
        name: empl.user,
        positionfit: <SoftProgress color="primary" value2={positionToShow} value={empl.position_fit * 100} variant="gradient" label />,
      })) : [];

      setRows(formattedData);
      // console.log('data', formattedData);
      // console.log('formatted data', formattedData);
      // console.log('Data1', response.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log(`Error: ${err.message}`);

      }
    }
  }



  return (
    <React.Fragment>
      <SoftButton variant="gradient" onClick={handleClickOpen}>
        <FaUserFriends />
      </SoftButton>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title">
          <h4>Information about employees</h4>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Grid ref={componentPDF} data-information="Position fit for employees">
              <Table
                columns={[
                  { name: "name", label: "name", align: "left" },
                  { name: "positionfit", label: "Position Fit", align: "left" },
                ]}
                rows={rows}
              />
            </Grid>
            <br />
            <SoftButton variant="gradient" style={{ float: 'right' }} iconOnly={false} onClick={generatePDF}>
              Print <FaFileDownload />
            </SoftButton>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

EmployeeInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  componentPDF: PropTypes.object.isRequired,
  generatePDF: PropTypes.func.isRequired,
  positionToShow: PropTypes.func.isRequired,
};

export default EmployeeInfoDialog;